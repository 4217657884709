import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Menu from './Menu';
import Dashboard from './Dashboard';
import Main from './Main';
import Feed from './Feed';
import Footer from './Footer';
import Landing from './Landing';
import SignUp from './SignUp';
import { ThemeProvider } from './ThemeContext';
import { PrivateRoute, PublicRoute } from './PrivateRoute';

function App() {
  return (
    <ThemeProvider>
      <Router>
        <div className="App">
          <Routes>
            {/* Public Routes */}
            <Route element={<PublicRoute restricted={false} />}>
              <Route path="/" element={<Landing />} />
            </Route>
            <Route element={<PublicRoute restricted={true} />}>
              <Route path="/signup" element={<SignUp />} />
            </Route>

            {/* Private Routes */}
            <Route element={<PrivateRoute />}>
              <Route path="/home" element={<AppWithHeaderAndFooter><Main /></AppWithHeaderAndFooter>} />
              <Route path="/dashboard" element={<AppWithHeaderAndFooter><Dashboard /></AppWithHeaderAndFooter>} />
              <Route path="/feed" element={<AppWithHeaderAndFooter><Feed /></AppWithHeaderAndFooter>} />
            </Route>
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

// Component to wrap routes that need Header and Footer
const AppWithHeaderAndFooter = ({ children }) => (
  <div className="App">
    <header className="App-header">
      <Menu />
    </header>
    <main className="App-main">
      {children}
    </main>
    <footer>
      <Footer />
    </footer>
  </div>
);

export default App;
