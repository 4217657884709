import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { auth } from "./firebase"; // Import auth only
import { onAuthStateChanged } from "firebase/auth"; // Import onAuthStateChanged directly

export const PrivateRoute = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  if (loading) return null; // or a loader

  return user ? <Outlet /> : <Navigate to="/" />;
};

export const PublicRoute = ({ restricted }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  if (loading) return null; // or a loader

  return user && restricted ? <Navigate to="/home" /> : <Outlet />;
};
