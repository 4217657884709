import React, { useEffect, useState, useCallback } from 'react';
import { db, auth } from './firebase';
import { collection, query, where, getDocs, orderBy, doc, updateDoc, increment, getDoc } from 'firebase/firestore';
import './Dashboard.css';
import { FaHeart, FaSortAlphaUp, FaSortAlphaDown } from 'react-icons/fa';  // Import FontAwesome beer icon
import Lottie from 'react-lottie';
import animationData from './images/lottieAnim.json'; // Adjust the path as necessary

function Feed() {
    const [responses, setResponses] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [sortMode, setSortMode] = useState('dateDesc'); // Default sorting mode
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Sort Options');
    const [isLoading, setIsLoading] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (value, label) => {
        handleSortChange(value);  // Call the provided sort function
        setSelectedOption(label); // Update the button label
        setIsOpen(false);         // Close the dropdown menu
    };

    const addLikeToResponse = async (responseId, ownerId) => {
        const user = auth.currentUser;
        if (user) {
            const responseRef = doc(db, `history/${ownerId}/responses`, responseId);
            try {
                const docSnap = await getDoc(responseRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    let userLikes = data.userLikes || [];
                    let newLikeResponse = data.likeResponse || 0;

                    if (userLikes.includes(user.uid)) {
                        userLikes = userLikes.filter(uid => uid !== user.uid);
                        newLikeResponse--;
                    } else {
                        userLikes.push(user.uid);
                        newLikeResponse++;
                    }

                    await updateDoc(responseRef, {
                        userLikes: userLikes,
                        likeResponse: newLikeResponse
                    });

                    console.log(`Like status updated for response ${responseId}`);
                    fetchResponses(); // Refresh the feed to show updated likes
                } else {
                    console.log("Document does not exist!");
                }
            } catch (error) {
                console.error("Error updating like status: ", error);
            }
        }
    };

    const toggleLike = () => {
        setIsActive(!isActive); // toggle the active state
    };

    const fetchResponses = useCallback(async () => {
        setIsLoading(true); // Start loading
        let allResponses = [];
        
        try {
            const usersRef = collection(db, "users");
            const snapshot = await getDocs(usersRef);
            const userIds = snapshot.docs.map(doc => doc.id); // UID is the document ID
            console.log("User IDs:", userIds);

            for (let userId of userIds) {
                console.log(`Fetching responses for user ID: ${userId}`);
                let q = query(collection(db, `history/${userId}/responses`), where("postResponse", "==", true));

                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    console.log(`No responses found for user ID: ${userId}`);
                    continue;
                }
                querySnapshot.forEach((doc) => {
                    const response = { id: doc.id, ownerId: userId, ...doc.data() };
                    if (response.textResponse) {
                        response.parsedTextResponse = processTextResponse(response.textResponse);
                    } else {
                        response.parsedTextResponse = [];
                    }
                    console.log(`Fetched response:`, response);
                    allResponses.push(response);
                });
            }

            // Apply sorting based on the current sortMode
            switch (sortMode) {
                case 'popularityDesc':
                    allResponses.sort((a, b) => b.likeResponse - a.likeResponse);
                    break;
                case 'popularityAsc':
                    allResponses.sort((a, b) => a.likeResponse - b.likeResponse);
                    break;
                case 'dateDesc':
                    allResponses.sort((a, b) => b.dateResponse.toDate() - a.dateResponse.toDate());
                    break;
                case 'dateAsc':
                    allResponses.sort((a, b) => a.dateResponse.toDate() - b.dateResponse.toDate());
                    break;
                case 'alphabetAsc':
                    allResponses.sort((a, b) => a.siteUrl.localeCompare(b.siteUrl));
                    break;
                case 'alphabetDesc':
                    allResponses.sort((a, b) => b.siteUrl.localeCompare(a.siteUrl));
                    break;
                default:
                    allResponses.sort((a, b) => b.dateResponse.toDate() - a.dateResponse.toDate());
            }

            setResponses(allResponses);
            setIsLoading(false); // End loading
            console.log("All Responses:", allResponses);
        } catch (error) {
            console.error("Error fetching responses:", error);
            setIsLoading(false);
        }
    }, [sortMode]);

    useEffect(() => {
        fetchResponses();
    }, [fetchResponses]);

    const handleSortChange = (newSortMode) => {
        setSortMode(newSortMode); // Update sort mode which triggers re-fetch
    };

    const processTextResponse = (textResponse) => {
        const sections = {};
        const responseLines = textResponse.split('\n');
        let currentSectionTitle = null;
        let currentText = [];
        let currentScore = null;

        responseLines.forEach(line => {
            const scoreMatch = line.match(/Score: (\d+(?:\/\d+)?)/); // Matches '80', '80/100'
            if (scoreMatch) {
                currentScore = scoreMatch[1]; // Capture just the numeric score
                // Skip adding this line to the currentText
                return; // This prevents the score line from being added to the text body
            }
            const foundTitle = criteriaList.find(title => line.startsWith(title)) || (line.startsWith("Overall Feedback") ? "Overall Feedback" : null);
            if (foundTitle) {
                if (currentSectionTitle !== null) {
                    sections[currentSectionTitle] = {
                        text: currentText.join('\n'),
                        score: currentScore
                    };
                }
                currentSectionTitle = foundTitle;
                currentText = [];
                currentScore = null; // Reset score for the next section
            } else {
                currentText.push(line);
            }
        });

        if (currentSectionTitle !== null) {
            sections[currentSectionTitle] = {
                text: currentText.join('\n'),
                score: currentScore
            };
        }

        return sections;
    };

    // List of all criteria
    const criteriaList = [
        "Design Foundations",
        "Visual Hierarchy",
        "Layout and Spacing",
        "Color",
        "Typography",
        "Theory",
        "Depth",
        "Design Assets",
        "Details"
    ];

    const togglePost = async (id, currentPostStatus) => {
        const user = auth.currentUser;
        if (user) {
            const responseRef = doc(db, `history/${user.uid}/responses`, id);
            try {
                await updateDoc(responseRef, {
                    postResponse: !currentPostStatus
                });
                console.log(`Post status for ${id} updated to: ${!currentPostStatus}`);

                // After updating, fetch the responses again to refresh the UI
                fetchResponses();
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    };

    return (
        <div className='dashboard'>
            {isLoading ? (
                <div className="loader">
                    <Lottie options={{ loop: true, autoplay: true, animationData: animationData, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} height={400} width={400} />
                </div>
            ) : (
                <>
                    <div className='dropOption'>
                        <div className="selectedOptionDrop" onClick={toggleDropdown}>{selectedOption}</div>
                        {isOpen && (
                            <div className='dropdownSorting'>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('dateDesc', 'Date')}>Date <FaSortAlphaDown /></div>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('dateAsc', 'Date')}>Date <FaSortAlphaUp /></div>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('popularityDesc', 'Most Popular')}>Most Popular <FaSortAlphaDown /></div>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('popularityAsc', 'Most Popular')}>Most Popular <FaSortAlphaUp /></div>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('alphabetDesc', 'Alphabet')}>Alphabet <FaSortAlphaDown /></div>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('alphabetAsc', 'Alphabet')}>Alphabet <FaSortAlphaUp /></div>
                            </div>
                        )}
                    </div>
                    {responses.map((response) => (
                        <div key={response.id} className="responseCard">
                            <div className='titleInformationBox'>
                                <div className="date">{response.email}</div>
                                <div className="date"><a className="responseSiteUrl" target='blank' href={response.siteUrl}>{response.siteUrl}</a></div>
                                <div className="date">{response.dateResponse?.toDate().toLocaleString()}</div>
                            </div>
                            <div className='responseCardSections'>
                                <div className='responseBoxBoard'>
                                    <div className='responseImageBox'>
                                    <img src={response.imageUrl} alt="Response Screenshot" className='responseImage' />
                                    </div>
                                </div>
                                <div className='responseBoxBoardRightFeed'>
                                    <div><h2>Overall Score: <span className='scoreStyle'>{response.overallScore}</span></h2></div>
                                    <div><b>About product:</b></div>
                                    <div>{response.aboutPost}</div>
                                    <div><b>Features:</b></div>
                                    <div>{response.featuresPost}</div>
                                    <div><b>Payment model:</b></div>
                                    <div>{response.paymentModel}</div>
                                </div>
                            </div>
                            {response.postResponse && (
                                <div className="likes" onClick={() => addLikeToResponse(response.id, response.ownerId)}>
                                    {response.likeResponse || 0} <span className={`likeButton ${isActive ? 'active' : ''}`} onClick={toggleLike}>
                                        <FaHeart />
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default Feed;
