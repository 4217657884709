    import React, { useEffect, useState, useCallback } from 'react';
    import { db, auth } from './firebase';
    import { collection, query, getDocs, orderBy, doc, updateDoc, where } from 'firebase/firestore';
    import './Dashboard.css';
    import Lottie from 'react-lottie';
    import animationData from './images/lottieAnim.json';

    function Dashboard() {
        const [responses, setResponses] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const [showDeleteModal, setShowDeleteModal] = useState(false);
        const [selectedId, setSelectedId] = useState(null);
        const [showPostModal, setShowPostModal] = useState(false);
        const [aboutPostText, setAboutPostText] = useState('');
        const [currentPostId, setCurrentPostId] = useState(null);

        const fetchResponses = useCallback(async () => {
            setIsLoading(true);
            const user = auth.currentUser;
            if (user) {
                try {
                    const q = query(
                        collection(db, `history/${user.uid}/responses`),
                        orderBy("dateResponse", "desc"),
                        where("saveResponse", "==", true)
                    );
                    const querySnapshot = await getDocs(q);
                    const fetchedResponses = querySnapshot.docs.map(doc => {
                        const data = doc.data();
                        return {
                            id: doc.id,
                            ...data,
                            parsedTextResponse: processTextResponse(data.textResponse)
                        };
                    });
                    setResponses(fetchedResponses);
                } catch (error) {
                    console.error("Error fetching responses:", error);
                }
            }
            setIsLoading(false);
        }, []);

        function DeleteConfirmationModal({ isOpen, onDelete, onCancel }) {
            if (!isOpen) return null;

            return (
                <div className="modalBackground">
                    <div className="modalContainer">
                        <h2>Are you sure you want to delete this post?</h2>
                        <div className="modalButtons">
                            <button onClick={onDelete}>Yes</button>
                            <button onClick={onCancel}>No</button>
                        </div>
                    </div>
                </div>
            );
        }

        function PostModal({ isOpen, onPost, onCancel }) {
            const [localAboutPostText, setLocalAboutPostText] = useState('');
            const [localFeaturesText, setLocalFeaturesText] = useState('');
            const [selectedPaymentModel, setSelectedPaymentModel] = useState('');
            const [isValid, setIsValid] = useState(false);
        
            useEffect(() => {
                const trimmedAboutText = localAboutPostText.trim();
                const trimmedFeaturesText = localFeaturesText.trim();
                // Validation: Both text areas should have content and a payment model must be selected
                setIsValid(
                    trimmedAboutText.length >= 100 && 
                    trimmedAboutText.length <= 300 &&
                    trimmedFeaturesText.length >= 50 &&
                    trimmedFeaturesText.length <= 200 &&
                    selectedPaymentModel
                );
            }, [localAboutPostText, localFeaturesText, selectedPaymentModel]);
        
            const handlePost = async () => {
                if (isValid) {
                    await onPost({
                        aboutPost: localAboutPostText.trim(),
                        featuresPost: localFeaturesText.trim(),
                        paymentModel: selectedPaymentModel
                    });
                    setLocalAboutPostText('');
                    setLocalFeaturesText('');
                    setSelectedPaymentModel('');
                }
            };            
        
            useEffect(() => {
                if (!isOpen) {
                    setLocalAboutPostText('');
                    setLocalFeaturesText('');
                    setSelectedPaymentModel('');
                }
            }, [isOpen]);
        
            if (!isOpen) return null;
        
            return (
                <div className="modalBackground">
                    <div className="modalContainer">
                        <h2>Write about your product:</h2>
                        
                        <textarea 
                            value={localAboutPostText} 
                            onChange={(e) => setLocalAboutPostText(e.target.value)} 
                            rows="5" 
                            cols="50"
                            placeholder="About the product"
                        />
                        
                        <h2>Product Features:</h2>
                        <textarea 
                            value={localFeaturesText} 
                            onChange={(e) => setLocalFeaturesText(e.target.value)} 
                            rows="5" 
                            cols="50"
                            placeholder="Product Features"
                        />
        
                        <h2>Select Payment Model:</h2>
                        <select
                            value={selectedPaymentModel}
                            onChange={(e) => setSelectedPaymentModel(e.target.value)}
                        >
                            <option value="" disabled>Select a payment model</option>
                            <option value="Freemium">Freemium</option>
                            <option value="Lifetime Access">Lifetime Access</option>
                            <option value="Subscription">Subscription</option>
                        </select>
        
                        <div className="modalButtons">
                            <button onClick={handlePost} disabled={!isValid}>Post</button>
                            <button onClick={onCancel}>Cancel</button>
                        </div>
                        
                        {!isValid && (
                            <p style={{ color: 'red' }}>
                                "About Product" should have 100-300 characters, "Features" should have 50-200 characters, and you must select a Payment Model.
                            </p>
                        )}
                    </div>
                </div>
            );
        }
        
        
        const promptDelete = (id) => {
            setSelectedId(id);
            setShowDeleteModal(true);
        };

        const confirmDelete = async () => {
            if (selectedId) {
                const user = auth.currentUser;
                if (user) {
                    const responseRef = doc(db, `history/${user.uid}/responses`, selectedId);
                    try {
                        await updateDoc(responseRef, { saveResponse: false });
                        console.log(`Save response for ${selectedId} updated to false`);
                        fetchResponses();
                    } catch (error) {
                        console.error("Error updating document: ", error);
                    }
                }
                setShowDeleteModal(false);
                setSelectedId(null);
            }
        };

        const cancelDelete = () => {
            setShowDeleteModal(false);
            setSelectedId(null);
        };

        useEffect(() => {
            fetchResponses();
        }, [fetchResponses]);

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        const criteriaList = [
            "Design Foundations",
            "Visual Hierarchy",
            "Layout and Spacing",
            "Color",
            "Typography",
            "Theory",
            "Depth",
            "Design Assets",
            "Details"
        ];

        const processTextResponse = (textResponse) => {
            const sections = {};
            const responseLines = textResponse.split('\n');
            let currentSectionTitle = null;
            let currentText = [];
            let currentScore = null;

            responseLines.forEach(line => {
                const scoreMatch = line.match(/Score: (\d+(?:\/\d+)?)/);
                if (scoreMatch) {
                    currentScore = scoreMatch[1];
                    return;
                }
                const foundTitle = criteriaList.find(title => line.startsWith(title)) || (line.startsWith("Overall Feedback") ? "Overall Feedback" : null);
                if (foundTitle) {
                    if (currentSectionTitle !== null) {
                        sections[currentSectionTitle] = {
                            text: currentText.join('\n'),
                            score: currentScore
                        };
                    }
                    currentSectionTitle = foundTitle;
                    currentText = [];
                    currentScore = null;
                } else {
                    currentText.push(line);
                }
            });

            if (currentSectionTitle !== null) {
                sections[currentSectionTitle] = {
                    text: currentText.join('\n'),
                    score: currentScore
                };
            }

            return sections;
        };

        const deleteResponse = async (id) => {
            const user = auth.currentUser;
            if (user) {
                const responseRef = doc(db, `history/${user.uid}/responses`, id);
                try {
                    await updateDoc(responseRef, {
                        saveResponse: false
                    });
                    console.log(`Save response for ${id} updated to false`);
                    fetchResponses();
                } catch (error) {
                    console.error("Error updating document: ", error);
                }
            }
        };

        const togglePost = async (id, currentPostStatus) => {
            if (currentPostStatus) {
                const user = auth.currentUser;
                if (user) {
                    const responseRef = doc(db, `history/${user.uid}/responses`, id);
                    try {
                        await updateDoc(responseRef, {
                            postResponse: !currentPostStatus
                        });
                        console.log(`Post status for ${id} updated to: ${!currentPostStatus}`);
                        fetchResponses();
                    } catch (error) {
                        console.error("Error updating document: ", error);
                    }
                }
            } else {
                setCurrentPostId(id);
                setShowPostModal(true);
            }
        };

        const confirmPost = async ({ aboutPost, featuresPost, paymentModel }) => {
            if (currentPostId && aboutPost) {
                const user = auth.currentUser;
                if (user) {
                    const responseRef = doc(db, `history/${user.uid}/responses`, currentPostId);
                    try {
                        await updateDoc(responseRef, {
                            postResponse: true,
                            aboutPost,
                            featuresPost,
                            paymentModel
                        });
                        console.log(`Post updated with aboutPost: ${aboutPost}, featuresPost: ${featuresPost}, paymentModel: ${paymentModel}`);
                        fetchResponses();
                    } catch (error) {
                        console.error("Error updating document: ", error);
                    }
                }
                setShowPostModal(false);
                setCurrentPostId(null);
            }
        };        
        
        
        const cancelPost = () => {
            setShowPostModal(false);
            setAboutPostText('');
            setCurrentPostId(null);
        };

        return (
            <div className='dashboard'>
                {isLoading ? (
                    <div className="loader">
                        <Lottie options={defaultOptions} height={400} width={400} />
                    </div>
                ) : (
                    responses.map((response) => (
                        <div key={response.id} className="responseCard">
                            <div className='titleInformationBox'>
                                <div className="date">Author: {response.email}</div>
                                <div className="date">{response.siteUrl}</div>
                                <div className="date">{response.dateResponse?.toDate().toLocaleString()}</div>
                            </div>
                            <div className='divider'></div>
                            <div className='responseCardSections'>
                                <div className='responseBoxBoard'>
                                    <div className='responseImageBox'>
                                    <img src={response.imageUrl} alt="Response Screenshot" className='responseImage' />
                                    </div>
                                </div>
                                <div className='responseBoxBoardRight'>
                                    {Object.entries(response.parsedTextResponse).map(([key, { text, score }], index) => (
                                        <div key={index} className='responseBox'>
                                            <h3>{key}{score && <span className="scoreStyle"> {score}</span>}</h3>
                                            <p style={{ whiteSpace: 'pre-wrap', fontSize: '1rem' }}>{text}</p>
                                        </div>
                                    ))}
                                </div>
                                <DeleteConfirmationModal
                                    isOpen={showDeleteModal}
                                    onDelete={confirmDelete}
                                    onCancel={cancelDelete}
                                />
                                <PostModal
                                    isOpen={showPostModal}
                                    onPost={confirmPost}
                                    onCancel={cancelPost}
                                />
                            </div>
                            <div className='actionPostButtons'>
                                <div className='actionButton' onClick={() => togglePost(response.id, response.postResponse)}>
                                    {response.postResponse ? 'Unpost' : 'Post'}
                                </div>
                                <div className='actionButton'>
                                    Share
                                </div>
                                <div className='actionButton' onClick={() => promptDelete(response.id)}>
                                    Delete
                                </div>
                            </div>
                            {response.postResponse && <div className="likes">Likes: {response.likeResponse || 0}</div>}
                        </div>
                    ))
                )}
            </div>
        );
    }

    export default Dashboard;
